import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NeedsRoleDirective} from "./needs-role.directive";
import {DataTablesModule} from "angular-datatables";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxSpinnerModule} from "ngx-spinner";
import {NgxFileDropModule} from "ngx-file-drop";
import {RouterModule} from "@angular/router";
import {FormInvalidDirective} from './form-invalid.directive';
import {HttpClientModule} from "@angular/common/http";
import { TableComponent } from './components/table/table.component';
import { InputFieldComponent } from './components/forms/input-field/input-field.component';
import { SelectFieldComponent } from './components/forms/select-field/select-field.component';
import { TranslatableInputComponent } from './components/forms/translatable-input/translatable-input.component';
import { ArrayInputComponent } from './components/forms/array-input/array-input.component';
import { CardComponent } from './components/card/card.component';
import { FormComponent } from './components/forms/form/form.component';


@NgModule({
  declarations: [
    NeedsRoleDirective,
    FormInvalidDirective,
    TableComponent,
    InputFieldComponent,
    SelectFieldComponent,
    TranslatableInputComponent,
    ArrayInputComponent,
    CardComponent,
    FormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxFileDropModule,
    HttpClientModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxFileDropModule,
    HttpClientModule,


    TableComponent,
    InputFieldComponent,
    SelectFieldComponent,
    TranslatableInputComponent,
    ArrayInputComponent,


    NeedsRoleDirective,
    FormInvalidDirective,
    CardComponent,
    FormComponent
  ]
})
export class SharedModule {
}
