<app-card [header]="header">
  <ngx-spinner
    type="ball-spin-clockwise"
    size="medium"
    color="#333333"
    bdColor="'rgb(0,0,0,0)'"
    [fullScreen]="false"
    [disableAnimation]="true"
    name="tableSpinner"
  >
  </ngx-spinner>
  <div
    class="table-responsive"
    [ngStyle]="{ opacity: isLoading ? '0' : '1' }"
    [class.loading-height]="isLoading"
  >
    <button
      *ngIf="addButtonRouterLink"
      class="btn btn-primary"
      style="margin-bottom: 12px; width: 100%"
      [routerLink]="addButtonRouterLink"
    >
      Add
    </button>
    <table
      datatable
      [dtTrigger]="dtTrigger"
      [dtOptions]="dtOptions"
      class="table table-bordered"
    >
      <thead class="bg-200 text-900">
        <tr>
          <th [class.sort]="!!header.sort" *ngFor="let header of tableColumns">
            {{ header.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let obj of _tableData">
          <td *ngFor="let header of tableColumns">
            <ng-container *ngIf="header.customHTMLFunction">
              <span [innerHTML]="header.customHTMLFunction(obj)"></span>
            </ng-container>
            <ng-container *ngIf="!header.customHTMLFunction">
              <ng-container *ngIf="header.key !== 'actions'">
                {{ getPropertiesOfObj(obj, header.key) }}
              </ng-container>
              <ng-container *ngIf="header.key === 'actions'">
                <div class="btn-group">
                  <button
                    class="btn btn-primary"
                    type="button"
                    role="tab"
                    *ngIf="
                      viewButtonRouterLink && viewButtonRouterLink.length > 0
                    "
                    [routerLink]="mapViewButtonRouterLink(obj.id!)"
                  >
                    <i class="fas fa-eye"></i>
                  </button>
                  <button
                    class="btn btn-warning"
                    type="button"
                    role="tab"
                    [routerLink]="
                      mapEditButtonRouterLink(obj.id!) || [obj.id, 'edit']
                    "
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </button>
                  <button
                    class="btn btn-danger"
                    type="button"
                    role="tab"
                    (click)="deleteFunction(obj)"
                    *ngIf="deleteFunction"
                    [needsRole]="userRoleForDelete"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-card>
