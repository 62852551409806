<div [class]="divClass" [ngSwitch]="type">
  <ng-container *ngSwitchCase="'checkbox'">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        [formControl]="control"
        [disabled]="disabled"
      />
      <label class="form-check-label">{{ name }}</label>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'radio'">
    <label class="form-label">{{ name }}</label>
    <div class="radio-group">
      <div *ngFor="let option of radioOptions">
        <label style="margin: 0">
          <input
            type="radio"
            [name]="radioName"
            [formControl]="control"
            [value]="option.value"
            style="display: none"
            class="peer"
          />
          <div class="peer-checked radio-option">
            {{ option.label }}
          </div>
        </label>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <label class="form-label">{{ name }}</label>
    <ng-container [ngSwitch]="type">
      <!-- Has to be done like this because of a bug in angular. See: https://github.com/angular/angular/issues/13243 -->
      <input
        type="file"
        class="d-block"
        (change)="
          fileDroppedFunction
            ? fileDroppedFunction($event)
            : defaultFileDrop($event)
        "
        [placeholder]="placeholder"
        [disabled]="disabled"
        [accept]="accept"
        *ngSwitchCase="'file'"
      />
      <input
        type="number"
        class="form-control"
        [formControl]="control"
        [placeholder]="placeholder"
        [disabled]="disabled"
        *ngSwitchCase="'number'"
      />
      <input
        type="date"
        class="form-control"
        [formControl]="control"
        [placeholder]="placeholder"
        [disabled]="disabled"
        *ngSwitchCase="'date'"
      />
      <textarea
        class="form-control"
        [formControl]="control"
        [placeholder]="placeholder"
        [rows]="rows"
        *ngSwitchCase="'textarea'"
      ></textarea>
      <input
        type="text"
        class="form-control"
        [formControl]="control"
        [placeholder]="placeholder"
        [disabled]="disabled"
        *ngSwitchDefault
      />
    </ng-container>
  </ng-container>
  <div
    class="invalid-feedback d-block"
    *ngIf="control.touched && control.invalid"
  >
    <ng-container *ngFor="let error of errors">
      <div *ngIf="control.hasError(error.key)">{{ error.message }}</div>
    </ng-container>
  </div>
</div>
