import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IDBObject} from "../../../models/base.model";
import {Observable, Subject, Subscription} from "rxjs";
import {DataTableDirective} from "angular-datatables";
import {UserRole} from "../../../models/user.model";

@Component({
  selector: 'app-table[tableColumns][tableData][header]',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnDestroy {
  UserRole = UserRole;

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  @Input() dtOptions: DataTables.Settings = {
    destroy: true,
    pageLength: 100,
    order: [0, 'asc']
  };
  dtTrigger: Subject<any> = new Subject();

  _tableData!: IDBObject[];
  _tableInstance: DataTables.Api;
  _timeouts: any[] = [];
  _subscriptions: Subscription[] = [];
  isLoading = true;

  @Input() header: string;
  @Input() tableColumns!: { name: string, key?: string | string[], sort?: boolean, customHTMLFunction?: (obj: any) => string}[];
  @Input() viewButtonRouterLink?: string[];
  @Input() editButtonRouterLink?: string[];
  @Input() deleteFunction?: (object: any) => void;
  @Input() userRoleForDelete = UserRole.ADMIN;

  @Input() set tableData(data: IDBObject[] | Observable<IDBObject[]>) {
    console.log('got data');
    if(data instanceof Observable) {
      const sub = data.subscribe((data) => {
        this.createTableInstance(data);
      });
      this._subscriptions.push(sub);
    } else {
      this.createTableInstance(data);
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  async createTableInstance(data: IDBObject[]) {
    for (let timeout of this._timeouts) {
      clearTimeout(timeout);
    }
    this.isLoading = true;
    this._tableData = data;
    if (this._tableInstance) {
      this._tableInstance.destroy();
    }
    this._timeouts.push(setTimeout(async () => {
      this.dtTrigger.next();
      this._tableInstance = await this.dtElement.dtInstance;
      this._timeouts.push(setTimeout(() => {
        this.isLoading = false;
      }, 500));
    }, 500));
  }

  mapViewButtonRouterLink(id: string) {
    return this.viewButtonRouterLink?.map((link) => {
      return link.replace('objectID', id);
    });
  }

  mapEditButtonRouterLink(id: string) {
    return this.editButtonRouterLink?.map((link) => {
      return link.replace('objectID', id);
    }).join('/');
  }

  getPropertiesOfObj(obj: any, key: string) {
    const value = key.split('.').reduce(function(obj, prop) {
      return obj && obj[prop];
    }, obj);
    return value;
  }

}
