<div class="overlay-background">
  <div class="bg-white rounded-lg p-4 text-center">
    <span class="h4">{{ title }}</span>
    <hr />
    <p>{{ message }}</p>
    <div class="d-flex" style="gap: 8px">
      <button
        class="btn btn-success"
        style="width: 100%"
        (click)="confirm.emit()"
      >
        YES
      </button>
      <button
        class="btn btn-danger"
        style="width: 100%"
        (click)="cancel.emit()"
      >
        NO
      </button>
    </div>
  </div>
</div>
